body {
  background: #EEEEEE;
  font-family: "Quicksand", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

* {
  scroll-margin-top: 100px;
}
